<template>
	<el-dialog :title="!dataForm.subjectNo ? '新增标的物' : '修改标的物'" :close-on-click-modal="false" :visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<!-- <el-form-item label="类型" prop="subjectKind">
				<el-select class="selItemInput" v-model="dataForm.subjectKind" placeholder="请选择">
					<el-option
					  v-for="item in subjectTypeList"
					  :key="item.displayValue"
					  :label="item.displayName"
					  :value="item.displayValue">
					</el-option>
				</el-select>
			</el-form-item> -->
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="编号" prop="projectNo" class="item">
						<el-input  v-model="dataForm.projectNo" placeholder="请输入"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="名称" prop="subjectName" class="item">
						<el-input  v-model="dataForm.subjectName" placeholder="请输入"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="序号" prop="sort" class="item">
						<el-input v-model="dataForm.sort" placeholder="请输入"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<div class="formItemTitle">
				参数信息
				<el-button v-preventReClick type="warning" size="small" icon="el-icon-plus" style="float: right;"
					@click="addParamList()">添加参数</el-button>
			</div>
			<el-row style="text-align: center; margin-bottom: 20px;">
				<el-col :span="10">参数名</el-col>
				<el-col :span="10">参数值</el-col>
				<el-col :span="4"></el-col>
			</el-row>
			<el-row style="text-align: center; margin-bottom: 20px;"
				v-for="(paramItem, paramIndex) in dataForm.paramInfoList">
				<el-col :span="10">
					<el-form-item label="" prop="paramName" label-width="0">
						<el-input class="selItemInput" v-model="paramItem.paramName" placeholder="请输入"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="10">
					<el-form-item label="" prop="paramValue" label-width="0">
						<el-input class="selItemInput" v-model="paramItem.paramValue" placeholder="请输入"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="4">
					<el-button v-preventReClick type="danger" size="small" @click="delParamList(paramIndex)">删除</el-button>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
			<el-button v-preventReClick class="rebtn" size="small" plain @click="visible = false">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>

import $common from "@/utils/common.js"
import navbar from "../../main-navbar"
export default {
	name: "subjectList-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			subjectTypeList: [],
			dataForm: {
				subjectNo: '',
				remark: '',
				subjectKind: '',
				subjectName: '',
				sort: '',
				projectNo: '',
				workNo: '',
				paramInfoList: [
					{
						paramName: "",
						paramValue: ""
					}
				]
			},
			paramInfoList: [
				{
					paramName: "",
					paramValue: ""
				}
			],
			dataRule: {
				// subjectKind: [
				//   { required: true, message: "类型不能为空", trigger: "blur" }
				// ],
				projectNo: [
					{ required: true, message: "编号不能为空", trigger: "blur" }
				],
				subjectName: [
					{ required: true, message: "名称不能为空", trigger: "blur" }
				],
				sort: [
					{ required: true, message: "排序不能为空", trigger: "blur" }
				]
			},
		}
	},
	computed: {
		updateSubjectList: {
			get() { return this.$store.state.common.navBarSubjectList },
			set(val) { this.$store.commit('common/updateSubjectList', val) }
		},
	},
	methods: {
		init(id) {
			this.dataForm = {
				subjectNo: '',
				remark: '',
				// subjectKind:'',
				subjectName: '',
				projectNo: '',
				sort: '',
				workNo: '',
				paramInfoList: [
					{
						paramName: "",
						paramValue: ""
					}
				]
			}
			this.dataForm.subjectNo = id ? id : "";
			this.dataForm.workNo = $common.getItem("workNo");
			this.getSubjectType();
			if (id != null) {
				this.getDetail();
			}
			this.visible = true;
			this.$nextTick(() => {
				this.$refs["dataForm"].resetFields()
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/subjectinfo/info/" + this.dataForm.subjectNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.projectNo = data.body.projectNo;
					this.dataForm.delFlg = data.body.delFlg;
					// this.dataForm.subjectKind = data.body.subjectKind;
					this.dataForm.subjectName = data.body.subjectName;
					this.dataForm.projectNo = data.body.projectNo;
					this.dataForm.subjectNo = data.body.subjectNo;
					this.dataForm.workNo = data.body.workNo;
					this.dataForm.paramInfoList = data.body.paramInfoList;
					this.dataForm.sort = data.body.sort;
				}
			});
		},
		getSubjectType() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectTypeList = data.body;
				}
			});
		},
		addParamList() {
			let _dataForm = this.dataForm;
			let _list = _dataForm.paramInfoList;
			_list.push({
				paramName: "",
				paramValue: ""
			})
			_dataForm.paramInfoList = _list;
			this.dataForm = _dataForm;
		},
		delParamList(index) {
			let _dataForm = this.dataForm;
			let _list = _dataForm.paramInfoList;
			_list.splice(index, 1);
			_dataForm.paramInfoList = _list;
			this.dataForm = _dataForm;
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					//this.subjectList = data.body;
					this.$store.commit('common/updateSubjectList', data.body)
					this.$store.commit('common/updateSelectSubjectNo', data.body[0].displayValue)
				}
			});
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/subjectinfo/save";
					if (this.dataForm && this.dataForm.subjectNo != 0) {
						_url = "/business/subjectinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
									this.getSubjectDropDown()
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
}
</script>
<style scoped>
.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: 1px solid #dadada;
}
</style>
