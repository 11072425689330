<template>
	<!-- 标的物设置 -->
	<div class="mod-config">
		<el-card style="height:650px">
			<el-row v-if="showError">
				<el-col :span="24">
					<el-alert title="温馨提示：每个项目至少保证一个标的物才可以正常使用！！！" type="error">
					</el-alert>
				</el-col>
			</el-row>
			<el-row>
				<div class="title">标的物</div>
				<div class="line" style="margin-bottom:2px"></div>
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
						v-if="showAdd">新增</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click=" subjectSelect()">标的物搜索</el-button>
				</el-col>
				<div class="line" style="margin-top:35px"></div>
				<!-- <el-col :span="12" style="text-align: right;">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :pager-count="5"
				  :page-size="pageLimit"
				  :total="totalCount">
				</el-pagination>
			</el-col> -->
			</el-row>
			<div class="cardList">
				<div class="cardListItem" v-for="(item, index) in dataList">
					<el-card body-style="padding: 10px 5px;" style="min-height:240px;background-color:rgb(241, 243, 243)">
						<el-row v-if="showEdit || showDelete" type="flex">
							<div style="border-bottom: 1px solid rgb(190, 187, 187);margin-bottom: 5px;">
								<el-button v-preventReClick type="text" size="mini"
									style="margin-bottom: 5px;margin-left:150px" @click="addOrUpdateHandle(item.subjectNo)"
									v-if="showEdit">修改</el-button>
								<el-button v-preventReClick type="text" size="mini" style="margin-bottom: 5px;"
									@click="deleteHandle(item.subjectNo)" v-if="showDelete">删除</el-button>
							</div>
						</el-row>
						<el-row style="margin-top:5px" type="flex">
							<el-col :span="8" style="padding-right: 10px;" class="setBorder" align="right">序号</el-col>
							<el-col :span="16" class="setBorder1">{{ item.sort
							}}</el-col>
						</el-row>
						<el-row type="flex">
							<el-col :span="8" style="padding-right: 10px;" class="setBorder2" align="right">编号</el-col>
							<el-col :span="16" class="setBorder3">{{ item.projectNo
							}}</el-col>
						</el-row>
						<el-row type="flex">
							<el-col :span="8" style="padding-right: 10px;" class="setBorder2" align="right">名称</el-col>
							<el-col :span="16" class="setBorder3">{{
								item.subjectName }}</el-col>
						</el-row>
						<el-row v-for="(subItem, subIndex) in item.paramInfoList" v-if="subIndex < 8" type="flex">
							<el-col :span="8" style="padding-right: 10px;" align="right" class="setBorder2">{{
								subItem.paramName }}</el-col>
							<el-col :span="16" class="setBorder3">{{
								subItem.paramValue }}</el-col>
						</el-row>
						<el-row style="text-align: center; height: 42px;" type="flex">
							<i class="el-icon-more" style="font-size: 32px; color: #e3e3e3;"
								v-if="item.paramInfoList && item.paramInfoList.length > 8"></i>
						</el-row>
					</el-card>
				</div>
			</div>
		</el-card>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<subject-select v-if="subjectSelectVisible" ref="subjectSelect" @refreshDataList="getDataList"></subject-select>
	</div>
</template>

<script>
import AddOrUpdate from "./subjectList-add-or-update"
import $common from "@/utils/common.js"
import SubjectSelect from "./subject-select";
export default {
	data() {
		return {
			showError: false,
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			addOrUpdateVisible: false,
			subjectSelectVisible: false,
			// pageNum: 0,
			// pageLimit: 20,
			// totalCount: 0,
			dataList: []
		}
	},
	components: {
		SubjectSelect,
		AddOrUpdate
	},
	mounted() {
		this.showAdd = $common.isAuth('subjectList:add');
		this.showSearch = $common.isAuth('subjectList.search');
		this.showEdit = $common.isAuth('subjectList:edit');
		this.showDelete = $common.isAuth('subjectList:delete');
		// this.menuList = $common.getItem("menuList")
		if (this.$store.state.common.navBarSubjectList.length === 0) {
			this.showError = true
			// 	let subMenuIdx = this.getSubjectMenuIndex()
			// 	this.$emit("setSubMenuIndex",subMenuIdx);
		}
		this.getDataList();
	},
	methods: {
		getSubjectMenuIndex() {
			let rtn = 0
			this.menuList[0].list.forEach(function (item, idx) {
				if (item.routerName === '/subjectList') {
					rtn = idx
				}
			})
			return rtn
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/subjectinfo/selectByWorkNo/" + $common.getItem("workNo"),
					method: "get",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body;
						if (this.dataList.length === 0) {
							this.showError = true
						} else {
							this.showError = false
						}
					}
				});
			}
		},
		subjectSelect() {
			this.subjectSelectVisible = true
			this.$nextTick(() => {
				this.$refs.subjectSelect.init()
			})
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					//this.subjectList = data.body;
					this.$store.commit('common/updateSubjectList', data.body)
					if (this.$store.state.common.navBarSubjectList.length === 0) {
						this.showError = true
						this.$store.commit('common/updateSelectSubjectNo', "")
					} else {
						if ($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined) {
							$common.setItem("subjectNo", data.body[0].displayValue);
							this.$store.commit('common/updateSelectSubjectNo', data.body[0].displayValue)
						} else {
							let isExist = false;
							this.subjectList.forEach(function (item, idx) {
								if (item.displayValue === $common.getItem("subjectNo")) {
									isExist = true;
								}
							});
							if (!isExist) {
								$common.setItem("subjectNo", data.body[0].displayValue);
								this.$store.commit('common/updateSelectSubjectNo', data.body[0].displayValue)
							}
						}
					}
				}
			});
		},
		// 删除
		deleteHandle(id) {
			this.$confirm(`您确定对选中标的物进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/subjectinfo/delete",
					method: 'post',
					data: this.$http.adornData([id], false)
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
								this.getSubjectDropDown()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
	}
}
</script>

<style lang="scss" scoped="scoped">
.cardList {
	margin-left: -8px;

	.cardListItem {
		margin-top: 25px;
		display: inline-block;
		vertical-align: top;
		width: 20%;
		padding: 0 8px;
		line-height: 32px;
	}
}

.setBorder {
	margin-left: 25px;
	width: 80px;
	border: solid 1px rgb(190, 187, 187);
	font-size: smaller;
	background-color: white;
}

.setBorder1 {
	padding-left: 10px;
	width: 120px;
	border: solid 1px rgb(190, 187, 187);
	border-left: 0;
	font-size: smaller;
	background-color: white;
}

.setBorder2 {
	margin-left: 25px;
	width: 80px;
	border-left: solid 1px rgb(190, 187, 187);
	border-right: solid 1px rgb(190, 187, 187);
	border-bottom: solid 1px rgb(190, 187, 187);
	font-size: smaller;
	background-color: white;
}

.setBorder3 {
	padding-left: 10px;
	width: 120px;
	border-right: solid 1px rgb(190, 187, 187);
	border-bottom: solid 1px rgb(190, 187, 187);
	border-left: 0;
	font-size: smaller;
	background-color: white;
}
</style>
